<template>
  <div id="app">
    <!-- <div id="nav">
			<router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link>
		</div> -->
	<toTop></toTop>
    <router-view />
  </div>
</template>

<style>
@import url("assets/css/common.css");
div {
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
.toBack {
  position: fixed;
  bottom: 200px;
  left: 0;
}
</style>

<script>
import toTop from "./components/Jushga";
export default {
	components: {toTop},
  data() {
    return {};
  },
  methods: {
	toBack() {
		let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
	}
  },
  watch: {
    $route(to) {
      let path = to.path;
      var info = JSON.parse(sessionStorage.getItem("topBottomInfo"));
      let index = 0;
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute(
          "content",
          info
            ? info.web_keywords
            : "佑护科技 互利网营销,企业信息化建设,网络开发,APP开发,新冠肺炎,新增病例,特朗普"
        );
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          info
            ? info.web_description
            : "佑护科技科技有限公司，是一家集企业信息化建设、网络开发、互利网营销于一体的综合性IT公司"
        );
      if (path == "/") {
        document.title = "佑护科技";
        index = 0;
      } else if (path == "/companyIntroduction") {
        document.title = "佑护科技   企业介绍";
        index = 1;
      } else if (path.indexOf("product") != -1) {
        document.title = "佑护科技   产品服务";
        index = 2;
      } else if (path.indexOf("partner") != -1) {
        document.title = "佑护科技   合作客户";
        index = 3;
      } else if (path.indexOf("new") != -1) {
        document.title = "佑护科技   资讯动态";
        index = 4;
      } else if (path == "/contactUs") {
        document.title = "佑护科技   联系我们";
        index = 5;
      }
      this.$store.commit("changeTab", index);
    },
  },
};
</script>


