import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import scroll from 'vue-seamless-scroll'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
/*  */
Vue.use(scroll)
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
/*  */
import api from "./server/api.js";
Vue.prototype.api = api;
import http from "./server/http.js";
Vue.prototype.http = http;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
