
var Debug = false;
var domain = '';
if(Debug == false){
	domain = 'http://www.qinhantangtop.com';
}


module.exports = {

	get_site_info: domain + "/api/index/get_site_info",//公司信息
	getServer: domain + "/api/index/getServer",//我们的服务(分类)
	getBanner: domain + "/api/index/getBanner",//首页轮播

	getNewsLIst: domain + "/api/news/get_list",//资讯列表
	getNewsInfo: domain + "/api/news/get_info",//资讯详情
	getNewsPrev: domain + "/api/news/prevNext",//上一篇下一篇

	getProductLIst: domain + "/api/product/get_list",//项目列表与合作商名称
	getProductInfo: domain + "/api/product/get_info",//项目详情
}
