import axios from 'axios';
import qs from "qs";
import {Toast, Notify} from 'vant';
// import router from "../router/index.js"

let baseURL = location.origin; // 请求基地址
let requestingCount = -1; // 请求数

function http(options) {
    return new Promise((resolve, reject) => {
        // var params = tools.deepClone(options.data || {});//深拷贝
        let params = options.data || {};
        // params.store_id = 1;

        if (options.header) {
            setCookie("PHPSESSID", options.header || "");
        }
        requestingCount++; // 请求数++

        let LoadingOption = { // loading配置
            message: '加载中...',
            forbidClick: true,
        }

        if (options.isLoading) { // 是否显示请求动画
            LoadingOption.message = options.loadText || '加载中...'
            // Toast.loading(LoadingOption);
        }

        const instance = axios.create({ // 创建axios实例
            baseURL,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded",
            },
            timeout: 30000, // 30秒后超时
            validateStatus: function (status) {
                // 定义可获得的http响应状态码
                // return true、设置为null或者undefined，promise将resolved,否则将rejected
                return status === 500 ? false : true
            },
        })

        let httpDefaultOpts = { //http默认配置
            method: options.method || "get",
            url: options.url,
            timeout: 600000,
            params: Object.assign(params),
            data: qs.stringify(Object.assign(params)),
        }

        if (options.method == 'get') { //判断是get请求还是post请求
            delete httpDefaultOpts.data
        } else {
            delete httpDefaultOpts.params
        }

        instance(httpDefaultOpts).then(res => { // 请求成功之后进行什么操作
            // 请求数小于等于0时关闭loading
            requestingCount--;
            requestingCount <= 0 ? Toast.clear() : Toast.loading(LoadingOption);

            switch (parseInt(res.data.code)) {
                case 1: // 正常
                    resolve(res.data)
                    break;
                default: // 其他报错
                    Notify({message: res.data.msg});
                    reject(res.data)
                    break;
            }

        }).catch(err => { // 接口报错/请求报错
            Toast.clear()
            Notify({message: '网络错误'});
            reject(err)
        })

    })
}


function setCookie(key, value, t) {
    var oDate = new Date();
    oDate.setDate(oDate.getDate() + t);
    document.cookie = key + "=" + value + "; expires=" + oDate.toDateString() + ";path=/;";

}

// function getCookie(key){
//     var arr1=document.cookie.split("; ");//由于cookie是通过一个分号+空格的形式串联起来的，所以这里需要先按分号空格截断,变成[name=Jack,pwd=123456,age=22]数组类型；
//     for(var i=0;i<arr1.length;i++){
//         var arr2=arr1[i].split("=");//通过=截断，把name=Jack截断成[name,Jack]数组；
//         if(arr2[0]==key){
//             return decodeURI(arr2[1]);
//         }
//     }
//
// }
// //封装一个移除cookie的函数
// function removeCookie(key){
//     setCookie(key,"",-1);//把cookie设置为过期
//
// }
export default http
