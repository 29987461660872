import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		topTabIndex: 0,
	},
	mutations: {
		changeTab(state, index) {
			state.topTabIndex = index
		}
	},
	
	modules: {}
})
