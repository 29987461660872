<template>
  <div>
      <div class="btn_run" v-show="isShow"   @click="backtop">
          <img src="../assets/images/top.png" alt="">
      </div>
    <!-- <div >顶部1</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.listenerFunction();
  },
  methods: {
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.listenerFunction);
    },
    handleScroll() {
      // handleScroll 和 methods 是同级
      if (window.pageYOffset > 1000) {
        //window.pageYOffset:获取滚动距离
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    //返回顶部
    backtop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style  scoped>
.btn_run {
cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.btn_run  img{  width: 50px;
  height: 50px;}
</style>